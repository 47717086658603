@charset 'UTF-8';
@import "/Users/naomi/Sites/saga-local/src/scss/utility/_flex.scss";
@import "/Users/naomi/Sites/saga-local/src/scss/utility/_media-queries.scss";
@import "/Users/naomi/Sites/saga-local/src/scss/utility/_mixin.scss";


.particles {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: $blue;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	canvas {
		display: block;
		vertical-align: bottom;
	}
}

.inview {
	opacity: 0;
	transition: opacity .8s .1s, transform .8s .1s;
	position: relative;
	&.on {
		opacity: 1;
	}
	&.visual_fadeInLeft {
		&.on {
			opacity: 1;
			@include transform(translate(-50%, -50%));
			@include SP {
				@include transform(translate(0, -50%));
			}
		}
	}
	&.fadeInLeft {
		@include transform(translateX(-100px));
		&.on {
			@include transform(translateX(0));
		}
	}
	&.fadeInRight {
		@include transform(translateX(100px));
		&.on {
			@include transform(translateX(0));
		}
	}
	&.fadeInBottom {
		opacity: 0;
		@include transform(translateY(30px));
		&.on {
			opacity: 1;
			@include transform(translateY(0));
		}
	}
	&.fadeInBottomDelay {
		@include transform(translateY(40px));
		transition-delay: .5s;
		&.on {
			@include transform(translateY(0));
		}
	}
	&.fadeInBottomDelay2 {
		@include transform(translateY(40px));
		transition-delay: .3s;
		&.on {
			@include transform(translateY(0));
		}
	}
	&.fadeInTop {
		@include transform(translateY(-100px));
		&.on {
			@include transform(translateY(0));
		}
	}
}